import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledSection = styled.section`
  width: 100%;
  padding: ${(props) => props.padding || '30px 0'};
  margin: ${(props) => props.margin || '30px 0'};

  @media screen and (max-width: 980px) {
    margin: 0px;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    color: ${colors.gray300};
    line-height: 1.8;
    font-family: ${fonts.secondary};

    strong {
      font-weight: 600;

      @media screen and (max-width: 980px) {
        font-weight: 500;
      }
    }
  }
`;

export const StyledTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 40px;
  font-family: ${fonts.primary};
  font-size: 72px;
  color: ${colors.gray300};
  line-height: 1.1;
  width: 85%;

  @media screen and (max-width: 980px) {
    font-size: 60px;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    font-size: 42px;
  }

  @media screen and (max-width: 520px) {
    font-size: 28px;
  }
`;

export const StyledDescription = styled.p`
  margin: 0;
`;
